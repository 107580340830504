<template>
  <el-container class="tokenmgr-detail">
    <el-main>
      <std-nav :paths="navData"></std-nav>
      <el-card v-if="form.wait===true" style="font-size:13px;">
        <div class="c_red" v-if="form.error!=null" v-text="form.error"></div>
        <div class="c_gray" v-else>加载中...</div>
      </el-card>
      <el-card class="detail-info">
        <el-form ref="detail_form" class="form-jg5" label-position="right" size="mini">
          <el-form-item class="flexbox">
            <el-input v-model="form.EB_DESC" placeholder="授权码说明" style="width:300px;">
              <template slot="prepend">名称</template>
            </el-input>
            <div class="flex-1">
              <el-input show-password v-model="form.EB_TOKEN">
                <template slot="prepend">授权码key</template>
              </el-input>
            </div>
          </el-form-item>
          <el-form-item class="flexbox">
            <el-radio-group v-model="form.EB_STATE">
              <el-radio-button label="Normal">启用</el-radio-button>
              <el-radio-button label="Disable">禁用</el-radio-button>
            </el-radio-group>
            <el-date-picker v-model="form.EB_RANGE" type="datetimerange" value-format="yyyy/MM/dd HH:mm:ss" :picker-options="pickerOptions"
              range-separator="至" start-placeholder="生效时间" end-placeholder="失效时间" align="right" class="flex-1">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <div style="text-align:right;">
              <el-button type="primary" size="mini">更新基本信息</el-button>
              <el-button type="primary" size="mini">重新生成授权码key</el-button>
            </div>
          </el-form-item>
        </el-form>
      </el-card>
      <el-card class="auth-list">
        <template #header>
          权限分配
          <div class="buttons">
            <el-button type="text" size="mini" @click="onAddAuth">添加权限</el-button>
            <el-button type="text" size="mini" @click="onSelectAll" style="margin-left:5px;">全选</el-button>
            <el-button type="text" size="mini" @click="onSelectAnti" style="margin-left:5px;">反选</el-button>
            <el-dropdown @command="onMultiCommand" style="margin-left:5px;">
              <el-button type="text" size="mini" :disabled="!hascheck">
                批量操作
                <i class="el-icon-arrow-down"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="Delete">移除</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </template>
        <std-table ref="auth_table" :cols="tableConfig1.cols" :data="auths" disable-selection forceRefresh>
          <template #action="{row,rowindex}">
            <el-link icon="el-icon-delete" @click="onRemoveAuth(row,rowindex)">移除</el-link>
          </template>
          <template #check="{row}">
            <el-checkbox v-model="row.check" size="mini" :checked="false"></el-checkbox>
          </template>
        </std-table>
      </el-card>
      <component v-if="dialog.show" :is="dialog.type" :data="dialog.data" @cancel="onCloseDialog" @success="dialog.event"></component>
    </el-main>
    <el-aside>
      <el-card class="upload-box" style="min-height:100px;">
        <template #header>
          操作
        </template>
        <div style="">
          <!-- <el-button type="primary" size="mini" @click="onSave">test
          </el-button> -->
          <div style="font-size: 13px;padding: 10px 0;color: #626161;">
            无可用操作
          </div>
        </div>
      </el-card>
    </el-aside>
  </el-container>
</template>
<script>
import moment from 'moment'
import winSelectAuth from './win-select-auth'
export default {
  components: {
    winSelectAuth
  },
  data() {
    return {
      navData: [
        { text: '系统管理', path: "/SysMgr" },
        { text: '授权码管理', path: '/TokenMgr/Index' },
        '授权码详情'
      ],
      form: {
        EB_TOKEN: '',
        EB_DESC: '',
        EB_RANGE: [],
        EB_STATE: 'Normal'
      },
      auths: [],
      pickerOptions: {
        shortcuts: [{
          text: '一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            end.setTime(end.getTime() + 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [
              moment(start).format('yyyy/MM/dd 00:00:00'),
              moment(end).format('yyyy/MM/dd 00:00:00')
            ]);
          }
        }, {
          text: '一年',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            end.setTime(end.getTime() + 3600 * 1000 * 24 * 365);
            picker.$emit('pick', [
              moment(start).format('yyyy/MM/dd 00:00:00'),
              moment(end).format('yyyy/MM/dd 00:00:00')
            ]);
          }
        }]
      },
      dialog: {
        show: false,
        type: '',
        data: {},
        event: null
      },
      check_list: []
    }
  },
  computed: {
    token_id() {
      return this.$route.params.token_id
    },
    UserData() {
      return this.$store.state.global.LoginInfo
    },
    tableConfig1() {
      return {
        cols: [
          { format: 'check', width: 35 },
          { prop: 'EB_APP_NAME', label: '应用名' },
          { prop: 'EB_AUTH_DESC', label: '权限名' },
          { prop: 'EB_PATH', label: '路径' },
          {
            prop: 'EB_STATE',
            show_type: 'option',
            width: 60,
            options: [
              { text: '正常', value: 'Normal', color: '#cccccc' },
              { text: '禁用', value: 'Disable', color: 'red' }
            ]
          },
          { format: 'action', width: 80 },
        ],
        pageSize: 15,
      }
    },
    hascheck() {
      let flag = this.auths.findIndex(p => p.check) != -1
      return flag
    }
  },
  methods: {
    onCloseDialog() {
      this.dialog.show = false
    },
    onClose() {
      this.$emit('cancel')
    },
    onSave() {
      let form = this.form
      let postData = {
        role_id: form.role_id,
        role_desc: form.role_desc,
        remark: form.remark
      }
      let valiResult = this.Bus.validData(postData, {
        require: [
          { prop: 'role_id', name: '角色ID' },
          { prop: 'role_desc', name: '角色名称' }
        ]
      })
      if (!valiResult) {
        return
      }

      this.Bus.ActionFunc(create ? `Role-CreateDetail` : `Role-UpdateDetail`, postData, data => {
        this.Bus.msg_success('保存成功!')
        this.$emit('success')
      });
    },
    onAddAuth() {
      let me = this
      this.$set(this, 'dialog', {
        show: true,
        type: 'win-select-auth',
        data: {
          token_id: this.token_id
        },
        event() {
          me.$set(me, 'dialog', {
            show: false
          })
          me.RefreshAuths();
        }
      })
    },
    onRemoveAuth(item) {
      let me = this
      this.Bus.msg_confirm(`确定要移除该权限吗?`, _ => {
        this.Bus.ActionFunc("TokenMgr-RemoveAuth", {
          token_id: me.token_id,
          app_id: item.EB_APP_ID,
          path: item.EB_PATH
        }, data => {
          me.Bus.msg_success('操作成功');
          me.RefreshAuths();
        })
      })
    },

    RefreshAuths() {
      let me = this
      let user_id = me.UserData.user_id
      me.Bus.ActionFunc("TokenMgr-GetDetailAuths", {
        token_id: me.token_id,
        user_id: user_id
      }, data => {
        data.auths.forEach(p => {
          p.check = false
        })
        me.$set(me, 'auths', data.auths)
      })
    },

    onSelectAll() {
      for (const path of this.auths) {
        this.$set(path, 'check', true)
      }
    },
    onSelectAnti() {
      for (const path of this.auths) {
        this.$set(path, 'check', !path.check)
      }
    },
    onMultiCommand(cmd) {
      let me = this
      if (cmd == "Delete") {
        this.Bus.msg_confirm('确定要移除这些权限吗?', _ => {
          let checkPaths = this.auths.filter(p => p.check);
          let postData = {
            token_id: me.token_id,
            list: checkPaths.map(p => ({
              app_id: p.EB_APP_ID,
              path: p.EB_PATH
            }))
          }
          this.Bus.ActionFunc("TokenMgr-RemoveAuths", postData, data => {
            this.Bus.msg_success('更新成功!')
            //更新状态
            me.RefreshAuths();
          })
        })
      }
    }
  },
  mounted() {
    let me = this
    let user_id = me.UserData.user_id
    if (this.Bus.isEmpty(me.token_id)) {
      this.$router.push({ path: '/TokenMgr/Index' })
      return
    }
    me.Bus.ActionFunc("TokenMgr-GetDetail", {
      token_id: me.token_id,
      user_id: user_id
    }, data => {
      let info = data.token_info
      me.$set(me, 'form', {
        EB_DESC: info.EB_DESC,
        EB_STATE: info.EB_STATE,
        EB_RANGE: [info.EB_START_TIME, info.EB_END_TIME],
        EB_TOKEN: info.EB_TOKEN
      })
      data.auths.forEach(p => {
        p.check = false
      })
      me.$set(me, 'auths', data.auths)
    })
  }
}
</script>
<style lang="scss">
.tokenmgr-detail {
  width: 1200px;
  margin: 0 auto;
  > .el-main {
    > .el-card + .el-card {
      margin-top: 10px;
    }
    > .detail-info .el-card__body {
      padding-bottom: 0;
    }
    > .auth-list {
      .buttons {
        float: right;
      }
      .el-card__body {
        padding: 0;
      }
    }
  }
}
</style>
