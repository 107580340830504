<template>
  <el-dialog title="添加权限" :visible="true" :before-close="onClose" width="800px" class="tokenmgr-win-select-role">
    <el-container>
      <el-main style="padding:0">
        <std-table ref="auth_table" :cols="tableConfig1.cols" :data="roles" disable-selection style="height:400px;"
          :pageindex.sync="tableConfig1.pageIndex" :pagesize.sync="tableConfig1.pageSize" :dataTotal="tableConfig1.total" paging @refresh="onRefresh">
          <template #action="{row}">
            <el-link @click="onLookDetail(row.EB_ROLE_ID)" style="margin-right:5px;">查看</el-link>
            <el-checkbox v-if="!row.exists" @change="onSelectRole(row,$event)" size="mini" :value="!!selRoles[`${row.EB_APP_ID}|${row.EB_PATH}`]">
              <span :class="{isnocheck:!selRoles[`${row.EB_APPID}|${row.EB_PATH}`]}">选中</span>
            </el-checkbox>
            <span v-else class="exist">(重复)</span>
          </template>
        </std-table>
        <div class="checklist">
          <el-divider content-position="left">已选的应用权限( 应用名<sup>(数量)</sup> )</el-divider>
          <span v-if="Object.keys(newrole).length==0" class="emptytips">尚未选择</span>
          <el-badge v-for="(item,appid) in newrole" :key="appid" :value="item.paths.length" class="item">
            <el-tag closable @close="onRemoveSel(item,appid)">{{item.desc}}</el-tag>
          </el-badge>

        </div>
      </el-main>
      <el-footer>
        <el-button type="primary" size="mini" icon="el-icon-s-claim" :disabled="newrole.length==0" @click="onSave">提交</el-button>
        <el-button type="primary" size="mini" icon="el-icon-refresh-right" @click="onClose">取消</el-button>
      </el-footer>
    </el-container>
  </el-dialog>
</template>
<script>
export default {
  props: {
    data: Object
  },
  data() {
    return {
      tableConfig1: {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
        cols: [
          { label: '来源角色', prop: 'EB_ROLE_DESC', width: 150 },
          { label: '应用名', prop: 'EB_APP_NAME', width: 150 },
          { label: '权限名', prop: 'EB_AUTH_DESC' },
          { label: '操作', format: 'action', width: 110 }
        ]
      },
      roles: [],
      /*
      {
        应用ID:{
          desc:'应用名称',
          paths:[
            {路径,来源角色}
          ]
        }
      }
      */
      newrole: {}
    }
  },
  computed: {
    selRoles() {
      let obj = {}
      var apps = Object.keys(this.newrole)
      apps.forEach(appid =>
        this.newrole[appid].paths.forEach(p =>
          obj[`${appid}|${p.path}`] = true
        )
      )
      //this.newrole.forEach(p => obj[p.role_id] = true)
      return obj;
    },
    UserData() {
      return this.$store.state.global.LoginInfo
    }
  },
  methods: {
    onClose() {
      this.$emit('cancel')
    },
    onRefresh() {
      let { pageIndex, pageSize } = this.tableConfig1
      this.Bus.ActionFunc("TokenMgr-GetAllowAuths", {
        pageIndex: pageIndex,
        pageSize: pageSize,
        user_id: this.UserData.user_id,
        token_id: this.data.token_id
      }, data => {
        this.$set(this, 'roles', data.list)
      })
    },
    onSave() {
      let paths = []
      let apps = Object.keys(this.newrole)
      apps.forEach(appid =>
        this.newrole[appid].paths.forEach(p =>
          paths.push({
            app_id: appid,
            path: p.path,
            role_id: p.role_id
          })
        )
      )
      if (paths.length == 0) {
        this.Bus.msg_waring('请选择要添加的权限')
        return;
      }
      let postData = {
        token_id: this.data.token_id,
        paths: paths
      }
      this.Bus.ActionFunc("TokenMgr-AppendPath", postData, data => {
        this.Bus.msg_success('新增成功!')
        this.$emit("success", {})
      })
    },
    onLookDetail(role_id) {

    },
    onSelectRole(row, checked) {
      let appid = row.EB_APP_ID
      if (checked) {
        if (!this.newrole[appid]) {
          this.$set(this.newrole, appid, {
            desc: row.EB_APP_NAME,
            paths: []
          })
        }
        this.newrole[appid].paths.push({
          role_id: row.EB_ROLE_ID,
          role_desc: row.EB_ROLE_DESC,
          path: row.EB_PATH,
          path_desc: row.EB_AUTH_DESC
        })
      } else {
        if (!this.newrole[appid]) {
          return
        }
        let paths = this.newrole[appid].paths
        for (let i = 0; i < paths.length; i++) {
          if (paths[i].path == row.EB_PATH) {
            paths.splice(i, 1)
            break;
          }
        }
      }
    },
    onRemoveSel(item, appid) {
      this.$delete(this.newrole, appid)
    }
  },
  mounted() {
    let me = this
    this.onRefresh()
  }
}
</script>
<style lang="scss">
.tokenmgr-win-select-role {
  .el-dialog {
    > .el-dialog__body {
      .el-container {
        min-height: 200px;
        > .el-footer {
          text-align: right;
          height: 40px !important;
        }
        .isnocheck {
          color: #c4c4c4;
        }
        .el-table .el-table__row td {
          padding: 5px 0;
        }
        .checklist {
          .el-divider {
            margin: 15px 0;
          }
          .el-tag {
            margin-right: 5px;
          }
          border: 1px solid gray;
          border-left: 0;
          border-right: 0;
          padding-bottom: 5px;
          margin-bottom: 5px;
        }
        .exist {
          color: #59a1bd;
          font-size: 13px;
        }
      }
    }
  }
}
</style>
